import { Box, CssBaseline } from '@mui/material';
import { AppProvider } from '@seedbin/ui-commons';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Landing } from './Landing';

export function App(): JSX.Element {
  return (
    <AppProvider appId="landing" appName="Landing Page">
      <Box display="flex" flexDirection="column" style={{ minHeight: '100vh' }}>
        <CssBaseline />
        <Routes>
          <Route path="/" element={<Landing />}></Route>
          <Route path="*" element={<Navigate to="/" />}></Route>
        </Routes>
      </Box>
    </AppProvider>
  );
}
